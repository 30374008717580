import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import DownloadIcon from '@mui/icons-material/Download';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { gql, useMutation, useQuery } from '@apollo/client';
import IconButton from '@mui/material/IconButton';
import { useRouteMatch } from "react-router-dom"
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Skeleton from '@mui/material/Skeleton';
import Moment from "moment";
import { ReactComponent as MySvgFile } from '../../../Images/activity.svg'
import LogTrigger from '../../../LogTrigger';
import { CONFIG_APP_BASE_COMPANY } from '../../../configApp';

export const HISTORY = gql`
query historyquery($name: String!){
  app(name:$name){
  releases{
    nodes{
      id 
      version 
      reason 
      description 
      image
      status 
      port
      stable
      user {
        id 
        email 
        name
      }	
      createdAt
      archiveUrl
    }
  }
  }}`

const rows = []
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'stable', numeric: false, disablePadding: false, label: 'Stable' },
  { id: 'image', numeric: false, disablePadding: false, label: 'Description' },
  { id: 'version', numeric: true, disablePadding: false, label: 'Version ' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status ' },
  { id: 'id', numeric: false, disablePadding: false, label: 'Completed By' },
  { id: 'archiveUrl', numeric: false, disablePadding: false, label: 'Download' },
  { id: 'createdAt', numeric: false, disablePadding: false, label: 'Timestamp ' },
];

const headCells2 = [
  { id: 'stable', numeric: false, disablePadding: false, label: 'Stable' },
  { id: 'image', numeric: false, disablePadding: false, label: 'Description' },
  { id: 'version', numeric: true, disablePadding: false, label: 'Version ' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status ' },
  { id: 'id', numeric: false, disablePadding: false, label: 'Completed By' },
  { id: 'createdAt', numeric: false, disablePadding: false, label: 'Timestamp ' },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
      {CONFIG_APP_BASE_COMPANY === "zeke" && headCells2.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'left'}
            padding={'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        {CONFIG_APP_BASE_COMPANY === "nife" && headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'left'}
            padding={'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  maintext: {
    color: theme.palette.text.secondary
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export default function ActivityTable() {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const match = useRouteMatch();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const { data, loading, error } = useQuery(HISTORY, { variables: { name: match.params.id } })
  if (loading) {
    return (
      <div>
        <Skeleton style={{ margin: "10px" }} />
        <Skeleton variant="rectangular" height={100} style={{ margin: "10px" }} />
      </div>
    );
  }
  if (data) {
    LogTrigger(`Activity data length - ${data && data.app.releases.nodes && data.app.releases.nodes.length}`, "error", window.location.href, "HISTORY q")
  }
  if (error) {
    LogTrigger(error.message, "error", window.location.href, "HISTORY q")
  }

  return (
    <div className={classes.root}>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        onPageChange={()=>console.log("click")}
        count={data && data.app.releases.nodes && data.app.releases.nodes.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />


      {data && data.app.releases.nodes ?
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={data && data.app.releases.nodes && data.app.releases.nodes.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(data.app.releases.nodes, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;
          
                  return (
                    <TableRow
                      hover

                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >

                      <TableCell component="th" id={labelId} scope="row">
                        <IconButton aria-label="expand row" size="small">

                          {row.stable ? <CheckCircleIcon style={{ color: "green" }} /> : <ErrorIcon style={{ color: "#ff4845" }} />}

                        </IconButton>
                      </TableCell>

                      <TableCell className={classes.maintext} align="left">{row.image}</TableCell>
                      <TableCell className={classes.maintext} align="left">{row.version}</TableCell>
                      <TableCell align="left" className={classes.maintext} >
                        {row.status.toUpperCase()}
                      </TableCell>

                      <TableCell align="left" className={classes.maintext} >
                        {row.user.name}
                      </TableCell>
                      {CONFIG_APP_BASE_COMPANY==="nife" && <TableCell align="left">


                        {row.archiveUrl ? <a href={row.archiveUrl} download="myFile"> <IconButton aria-label="expand row" size="small">

                          <DownloadIcon className={classes.maintext} />

                        </IconButton></a> : <p>Not Found</p>}
                      </TableCell>}

                      <TableCell align="left" className={classes.maintext} >{Moment(new Date(row.createdAt), "YYYY MM DD hh:mm:ss").local("de").fromNow()}</TableCell>


                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 23) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer> : <div>
          <h3 style={{ marginBottom: "0px" }}> No data</h3> <div style={{ color: "grey" }}> No Activity to show</div>
          <MySvgFile style={{ width: "100%", height: "100%", minWidth: "100px", marginTop: "5px", maxWidth: "600px" }} />
        </div>}

    </div>
  );
}
