import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    Box,
    Card,
    CardContent,
    Typography,
    CircularProgress,
    Grid,
    LinearProgress,
} from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import UpdateIcon from '@mui/icons-material/Update';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import BuildIcon from '@mui/icons-material/Build';
import { CONFIG_APP_DORA_METRICS_BASE_URL } from '../configApp';

const metricDescriptions = {
    changeFailureRate: 'Measures the percentage of changes that result in a failure in production.',
    deploymentFrequency: 'Measures how often the organization deploys code to production.',
    leadTimeForChange: 'Measures the time it takes for a code change to go from code committed to code successfully running in production.',
    meanTimeToRepair: 'Measures the average time it takes to recover from a failure in production.',
};

const metricIcons = {
    changeFailureRate: <ErrorOutlineIcon fontSize="large" />,
    deploymentFrequency: <UpdateIcon fontSize="large" />,
    leadTimeForChange: <AccessTimeIcon fontSize="large" />,
    meanTimeToRepair: <BuildIcon fontSize="large" />,
};

const isValidMetric = (metric) => {
    if (metric === undefined || metric === null || metric === '') {
        return false;
    }
    return true;
};

const generateExplanation = (key, value) => {
    switch (key) {
        case 'changeFailureRate':
            return `A change failure rate of ${value} indicates that ${parseFloat(value) === 0 ? 'none' : 'a very low percentage'} of the changes result in failures. This is a good sign indicating stability in deployments.`;
        case 'deploymentFrequency':
            return `The deployment frequency of ${value} means the application is deployed ${parseFloat(value) === 0 ? 'rarely' : value.replace('/day', ' times per day')}.`;
        case 'leadTimeForChange':
            return `The lead time for change is ${value.replace(/:/g, ' ')}. This is the time it takes for a change to go from code committed to running in production.`;
        case 'meanTimeToRepair':
            return `The mean time to repair is ${value.replace(/:/g, ' ')}. This measures how quickly issues in production are resolved.`;
        default:
            return '';
    }
};

const DoraMetrics = ({githubrepo,token}) => {
    const [metrics, setMetrics] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchMetrics = async () => {
            try {
                const response = await axios.get(`${CONFIG_APP_DORA_METRICS_BASE_URL}/metrics?repo=${githubrepo ? githubrepo : ""}&token=${token ? token : ""}`);
                const data = response.data;

                if (
                    isValidMetric(data.changeFailureRate) &&
                    isValidMetric(data.deploymentFrequency) &&
                    isValidMetric(data.leadTimeForChange) &&
                    isValidMetric(data.meanTimeToRepair)
                ) {
                    setMetrics(data);
                } else {
                    throw new Error('Invalid data format received');
                }
            } catch (err) {
                setError('Failed to fetch metrics');
            } finally {
                setLoading(false);
            }
        };

        fetchMetrics();
    }, []);

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <Typography variant="h6" color="error">{error}</Typography>
            </Box>
        );
    }

    return (
        <Box pt={2}>
         <Typography variant="h4" sx={{ p: 4, pl: 0 }} component="h1" >DORA (DevOps Research and Assessment)</Typography>
        <Typography sx={{fontSize:"12px",mb:2,color:"grey"}}>Metrics are a set of key performance indicators (KPIs) used to measure and evaluate the effectiveness of DevOps practices within an organization.</Typography>
        {/* <Typography sx={{mb:1}}>Showing results for {githubrepo}</Typography> */}
            <Grid container spacing={3}>
                {metrics && Object.entries(metrics).map(([key, value]) => (
                    <Grid item xs={12} sm={6} md={3} key={key}>
                 
                        <Card sx={{
                            borderRadius: "12px", 
                            borderTop:"5px solid #fc8c00",
                            borderTopLeftRadius:"5px",
                            borderTopRightRadius:"5px",
                            boxShadow: "rgba(17, 12, 46, 0.04) 0px 20px 30px 0px",
                            transition: "box-shadow 0.3s ease-in-out",
                            '&:hover': {
                                boxShadow: "0 7px 20px 5px #00000014",
                            }
                        }}>
                            <CardContent>
                                <Box display="flex" alignItems="center" sx={{ color: "primary.main" }} mb={2}>
                                    {metricIcons[key]}
                                    <Typography variant="h6" gutterBottom sx={{ ml: 2, textTransform: "capitalize", fontSize: "1rem", mb: 0 }}>
                                        {key.replace(/([A-Z])/g, ' $1').trim()}
                                    </Typography>

                                </Box>
                                <Typography variant="body2" color="textSecondary" gutterBottom>
                                    {metricDescriptions[key]}
                                </Typography>
                                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                                    {value}
                                </Typography>
                                <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                                    {generateExplanation(key, value)}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default DoraMetrics;
