import { gql, useMutation, useQuery } from '@apollo/client';
import { Button, CircularProgress, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Grid, LinearProgress, TextField, Typography } from '@mui/material'
import { useSnackbar } from 'notistack';
import React, { useState } from 'react'
import { FaCheckCircle, FaSlack } from 'react-icons/fa'
import { encodeAes } from '../Settings/UserLevelPAT';


export const ADD_SLACK_NOTIFICATION = gql`
mutation updateUserwebhookURLSlackMutation($webhookURL: String! ){
    updateUserwebhookURLSlack(webhookURL: $webhookURL)
}
`

export const VIEW_SLACK_NOTIFICATION = gql`
query{
    getUserById {
    slackWebhookURL
  }
}`


export default function SlackNotification({ fullScreen }) {
    const { enqueueSnackbar } = useSnackbar();

    const [openDialog, setopenDialog] = useState(false);
    const [slackWebHookURLValue, setslackWebHookURLValue] = useState("");
    const [showGuide, setshowGuide] = useState(false);
    const [isUpdate, setisUpdate] = useState(false);
    const handleCloseOpenDialog = () => {
        setopenDialog(false)
    }

    const handleSubmit = () => {
        addSlackWebHookUrl({ variables: { webhookURL: encodeAes(slackWebHookURLValue)  } })
    }


    const [addSlackWebHookUrl, { error: error }] = useMutation(ADD_SLACK_NOTIFICATION, {
        refetchQueries: [VIEW_SLACK_NOTIFICATION],
        onError: (err) => {
            setslackWebHookURLValue("")
            enqueueSnackbar(err.message, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })


        },
        onCompleted: () => {

            enqueueSnackbar("Successfully updated!", {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
            setopenDialog(false)

        }
    });

    const { loading: load, error: err, data: userdata } = useQuery(VIEW_SLACK_NOTIFICATION);

    if (load) { return "Loading..." }

    return (
        <div>
            {(userdata && userdata.getUserById && userdata.getUserById.slackWebhookURL && userdata.getUserById.slackWebhookURL !== "") ?
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                >

                    <FaCheckCircle style={{ color: "green" }} /> <Typography sx={{ mx: 1 }}> Slack notification is active</Typography>
                </Grid> :
                <Button onClick={() => { setopenDialog(true) }} startIcon={<FaSlack />}> Add Slack</Button>}
            <Dialog
                open={openDialog}
                maxWidth={"xs"}
                fullWidth
                PaperProps={{
                    elevation: 0,
                    sx: {
                        borderRadius: "12px",
                        border: "solid 1px gray",
                        boxShadow: (slackWebHookURLValue ? false : true) ? "#000 5px 5px, #000 10px 10px" : "undefined"

                    }
                }}
                onClose={handleCloseOpenDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle sx={{ pb: "0px" }} id="alert-dialog-title">{showGuide ? "Setting Up Slack Webhook " :
                    <>
                        {isUpdate ? "Update" : "Add"} <b>Slack Notification</b>
                    </>}
                
                    </DialogTitle>
                    {showGuide && <Button onClick={()=>setshowGuide(false)}>Go back</Button>}   
                <div>
                    {showGuide ? <DialogContent>
                        <Typography paragraph>
                            To receive Slack notifications, you need to set up an incoming webhook. Follow these steps:
                        </Typography>

                        <ol>
                            <li>
                                <strong>Sign in to Slack:</strong> If you don't have an account, create one on{' '}
                                <a href="https://slack.com/get-started" target="_blank" rel="noopener noreferrer">
                                    Slack's website
                                </a>
                                .
                            </li>
                            <li>
                                <strong>Create a Slack App:</strong> Go to the{' '}
                                <a href="https://api.slack.com/apps" target="_blank" rel="noopener noreferrer">
                                    Slack App Management
                                </a>{' '}
                                page and click on "Create New App."
                            </li>
                            <li>
                                <strong>Fill in App Details:</strong> Enter a name for your app, select your workspace, and click on "Create App."
                            </li>
                            <li>
                                <strong>Set Up Incoming Webhooks:</strong> In the left sidebar, navigate to "Incoming Webhooks" under the "Features" section.
                            </li>
                            <li>
                                <strong>Activate Incoming Webhooks:</strong> Toggle the switch to activate incoming webhooks.
                            </li>
                            <li>
                                <strong>Add a New Webhook:</strong> Scroll down to the "Webhooks" section and click on "Add New Webhook to Workspace."
                            </li>
                            <li>
                                <strong>Authorize the App:</strong> Authorize your app to access the selected workspace and choose a channel.
                            </li>
                            <li>
                                <strong>Copy the Webhook URL:</strong> After authorizing, you'll get a webhook URL. Copy it and paste here.
                            </li>
                        </ol>
                    </DialogContent>

                        : <DialogContent>
                            <TextField value={slackWebHookURLValue}
                                onChange={(e) => setslackWebHookURLValue(e.target.value)} size='small' fullWidth label="Slack webhook URL" placeholder='Enter Slack webhook URL' />

                            <Typography onClick={()=>setshowGuide(true)} sx={{ fontSize: "12px", mt: 1 }}>How to get slack webhook url? <b style={{color:"blue"}}>Show steps</b></Typography>
                        </DialogContent>}
                </div>
                <Collapse in={slackWebHookURLValue ? true : false}>
                    <DialogActions>
                        <Button fullWidth onClick={handleCloseOpenDialog}> Cancel </Button>
                        <Button
                            disabled={false}
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            disableElevation>
                            {isUpdate ? "Update" : "Create"}</Button>
                    </DialogActions>
                </Collapse>
            </Dialog>
            {fullScreen && <div>
                {(userdata && userdata.getUserById && userdata.getUserById.slackWebhookURL && userdata.getUserById.slackWebhookURL !== "") &&
                    <div>
                        <Typography sx={{ my: 1 }} noWrap variant='body2'>
                            Using {userdata.getUserById.slackWebhookURL.replace(/.(?=.{5})/g, '*')} to send slack notification!
                        </Typography>
                        <Button size="small" variant='outlined' sx={{ mr: 1 }} onClick={()=> {
                            setisUpdate(true);
                            setslackWebHookURLValue(userdata.getUserById.slackWebhookURL);
                            setopenDialog(true);
                            }}>Update webhook</Button>
                        <Button size="small" variant='outlined' color='error' onClick={() => { addSlackWebHookUrl({ variables: { webhookURL: "" } }) }}>remove webhook</Button>
                    </div>
                }

            </div>}
        </div>
    )
}
