import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Grid, Paper, Skeleton } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, BarChart, Bar } from 'recharts';
import moment from 'moment';
import { BarChart as BarIcon, ShowChart as LineIcon } from '@mui/icons-material';
import { CONFIG_APP_UPTIME_METRICS_BASE_URL } from '../configApp';

// StatsCard component to display individual stats in a card
const StatsCard = ({ title, value, icon: Icon, description, color = '#2e7d32' }) => (
    <Grid item xs={12} sm={4}>
        <Paper variant='outlined' sx={{ padding: 3, textAlign: 'center', borderRadius: 2, }}>
            <Icon sx={{ fontSize: 40, color: color }} />
            <Typography variant="h6" sx={{ marginTop: 1, fontWeight: 'bold' }}>{title}</Typography>
            <Typography variant="h5" sx={{ fontWeight: 'bold', color: color }}>{value}</Typography>
            <Typography variant="body2" sx={{ marginTop: 1, color: '#555' }}>{description}</Typography>
        </Paper>
    </Grid>
);

export const StatisticsCards = ({ totalCount, avgCount, maxCount, minCount, sampleInterval, earliestTimestamp, latestTimestamp }) => {
    // Format the time range using moment
    const timeRangeFormatted = `${moment(earliestTimestamp).fromNow()} - ${moment(latestTimestamp).fromNow()}`;

    return (
        <Grid container spacing={3} sx={{ marginTop: 3 }}>
            <StatsCard
                title="Total Count"
                value={totalCount}
                icon={BarIcon}
                description="The total number of DNS queries recorded within the sample interval."
                color="#1976d2"
            />
            <StatsCard
                title="Average Count"
                value={avgCount.toFixed(2)}
                icon={LineIcon}
                description="The average number of DNS queries recorded per interval."
                color="#ffa000"
            />
            <StatsCard
                title="Max Count"
                value={maxCount}
                icon={BarIcon}
                description="The maximum number of DNS queries recorded within any sample interval."
                color="#d32f2f"
            />
            <StatsCard
                title="Min Count"
                value={minCount}
                icon={LineIcon}
                description="The minimum number of DNS queries recorded within any sample interval."
                color="#388e3c"
            />
            <StatsCard
                title="Sample Interval"
                value={`${sampleInterval} seconds`}
                icon={LineIcon}
                description="The time interval between each data sample."
                color="#8e24aa"
            />
            <StatsCard
                title="Time Range"
                value={timeRangeFormatted}
                icon={BarIcon}
                description="The period of time from the earliest to the latest recorded data point."
                color="#0288d1"
            />
        </Grid>
    );
};

const DNSmetrics = ({appName}) => {
    const [data, setData] = useState(null);

    useEffect(() => {
        // Calculate the current date and 24 hours ago dynamically
        const currentDate = moment().toISOString();  // current date in ISO format
        const startDate = moment().subtract(1, 'days').toISOString();  // 24 hours ago

        // POST request to fetch data
        const fetchData = async () => {
            const requestBody = {
                appName: appName ? appName : "",
                startDatetime: startDate,
                endDatetime: currentDate,
                zoneTag: "b032a57f01eac20ad3c4e8ba2a05bb19",
            };

            try {
                const response = await fetch(`${CONFIG_APP_UPTIME_METRICS_BASE_URL}/fetch-metrics`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(requestBody),
                });
                const result = await response.json();
                setData(result);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    if (!data) {
        return <Skeleton height={100}/>;
    }

    // Safely extract the series data with default fallback
    const seriesData = data?.data?.viewer?.zones?.[0]?.series || [];
    const lineChartData = seriesData.map((item) => ({
        ts: item?.dimensions?.ts || 'N/A',
        count: item?.count || 0,
    }));

    // Calculating additional stats
    const sampleInterval = seriesData[0]?.avg?.sampleInterval || 0;
    const totalCount = seriesData.reduce((acc, item) => acc + (item?.count || 0), 0);
    const avgCount = totalCount / (seriesData.length || 1);
    const maxCount = Math.max(...seriesData.map(item => item?.count || 0));
    const minCount = Math.min(...seriesData.map(item => item?.count || 0));

    // Time Range
    const earliestTimestamp = seriesData[0]?.dimensions?.ts || 'N/A';
    const latestTimestamp = seriesData[seriesData.length - 1]?.dimensions?.ts || 'N/A';

    return (
        <div style={{ padding: '20px' }}>
        
            <Typography variant="h4" gutterBottom align="left" sx={{ marginBottom: 1 }}>
                DNS Monitoring
            </Typography>
            <Typography variant="p" align="left" sx={{ marginBottom: 2,  opacity: 0.8, }}>
                Visualizing DNS query trends and statistics
            </Typography>

            {/* Statistics Cards at the top */}
            <StatisticsCards
                totalCount={totalCount}
                avgCount={avgCount}
                maxCount={maxCount}
                minCount={minCount}
                sampleInterval={sampleInterval}
                earliestTimestamp={earliestTimestamp}
                latestTimestamp={latestTimestamp}
            />

            {/* Line Chart for DNS count over timestamp */}
            <Grid container spacing={3} justifyContent="center" sx={{ marginTop: 4 }}>
                <Grid item xs={12} sm={6}>
                <Paper variant='outlined' sx={{textAlign: 'center', borderRadius: 2, }}>
                        <CardContent>
                            <Typography variant="h6" align="center" sx={{ fontWeight: 'bold' }}>
                                DNS Uptime Trends
                            </Typography>
                            <Typography variant="body2" align="center" sx={{ marginBottom: 2,  opacity: 0.8, }}>
                                This chart shows the trend of DNS queries over time. The x-axis represents the timestamp, and the y-axis represents the query count.
                            </Typography>
                            {lineChartData.length === 0 ? (
                                <Typography variant="h6" align="center">No data available to display.</Typography>
                            ) : (
                                <ResponsiveContainer width="100%" height={300}>
                                    <LineChart data={lineChartData}>
                                        <XAxis dataKey="ts" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Line type="monotone" dataKey="count" stroke="#0088FE" strokeWidth={2} />
                                    </LineChart>
                                </ResponsiveContainer>
                            )}
                        </CardContent>
                    </Paper>
                </Grid>
         
                <Grid item xs={12} sm={6}>
                <Paper variant='outlined' sx={{textAlign: 'center', borderRadius: 2, }}>
                        <CardContent>
                            <Typography variant="h6" align="center" sx={{ fontWeight: 'bold' }}>
                                Count Distribution
                            </Typography>
                            <Typography variant="body2" align="center" sx={{ marginBottom: 2,  opacity: 0.8, }}>
                                This bar chart displays the distribution of DNS query counts over time.
                            </Typography>
                            {lineChartData.length === 0 ? (
                                <Typography variant="h6" align="center">No data available to display.</Typography>
                            ) : (
                                <ResponsiveContainer width="100%" height={300}>
                                    <BarChart data={lineChartData}>
                                        <XAxis dataKey="ts" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Bar dataKey="count" fill="#0088FE" />
                                    </BarChart>
                                </ResponsiveContainer>
                            )}
                        </CardContent>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
};

export default DNSmetrics;
