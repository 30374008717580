import React, { useState } from 'react';
import { Box, Rating, TextField, Typography, IconButton, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios'; // To make the API call

function FeedbackBox({ setShowFeedback, appName }) {
  const [rating, setRating] = useState(null);
  const [feedback, setFeedback] = useState('');
  const [showFeedbackInput, setShowFeedbackInput] = useState(false);

  // Function to save feedback when the user closes the box
  const saveFeedback = async () => {
    if (rating === null) return; // Don't save if no rating is selected

    try {
      const response = await axios.post('http://localhost:5000/save-rating', {
        rating,
        feedbackMessage: `App name: ${appName}, feedback: ${feedback}`, // Send null if feedback is empty
        userId: 123, // Assuming userId is 123, replace with dynamic user ID if needed
      });
      console.log('Feedback saved successfully:', response.data);
    } catch (error) {
      console.error('Error saving feedback:', error);
    }
  };

  // Handle rating change
  const handleRatingChange = (event, newValue) => {
    setRating(newValue);
    setShowFeedbackInput(newValue !== null); // Show feedback input if rating is selected
  };

  // Handle feedback message change
  const handleFeedbackChange = (event) => {
    setFeedback(event.target.value);
  };

  // Handle close button click and save feedback before closing
  const handleClose = () => {
    saveFeedback(); // Save feedback before closing
    setShowFeedback(false); // Close the feedback box
  };

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        backgroundColor: 'white',
        padding: 3, // Increased padding
        borderTopLeftRadius: 5,
        boxShadow: 5,
        width: 350, // Increased width
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        zIndex: 1000
      }}
    >
      {/* Close button in the top right */}
      <IconButton
        sx={{
          position: 'absolute',
          top: 8,
          right: 8,
        }}
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>

      <Typography variant="h6" sx={{fontSize:"1rem"}} gutterBottom>
        Rate your deployment experience
      </Typography>
      <Rating
        name="deployment-feedback"
        value={rating}
        onChange={handleRatingChange}
        max={5}
        precision={1}
        size="large"
      />
      {rating !== null && (
        <Typography variant="body2" color="textSecondary" sx={{ marginTop: 1 }}>
          {rating === 5 ? 'Excellent' : rating === 4 ? 'Good' : rating === 3 ? 'Average' : rating === 2 ? 'Poor' : 'Very Poor'}
        </Typography>
      )}
      {showFeedbackInput && (
        <>
        <TextField
          value={feedback}
          onChange={handleFeedbackChange}
          label="Your feedback"
          variant="outlined"
          multiline
          rows={4}
          sx={{ marginTop: 2, width: '100%' }}
        />

<Button
          variant="contained"
          color="primary"
          sx={{ marginTop: 2, width: '100%' }}
          onClick={handleClose}
        >
          Submit
        </Button>
        </>
        
      )}
    </Box>
  );
}

export default FeedbackBox;
