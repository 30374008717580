import { gql, useMutation, useQuery } from '@apollo/client';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { deepPurple } from '@mui/material/colors';
import Grid from '@mui/material/Grid';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { logout } from '../../actions/index';

import PersonIcon from '@mui/icons-material/Person';
import { Divider } from '@mui/material';
import { UploadCompanyLogo } from "../Settings/UploadCompanyLogo";
import { CropImage } from "../Settings/UploadImage";

import { BsPersonBoundingBox } from "react-icons/bs";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import UpgradePlan from '../../Containers/UpgradePlan';
import LogTrigger from '../../LogTrigger';
import "./ProfilePhoneNumber.css";
import { Menu, MoreHoriz, OpenInBrowser } from '@mui/icons-material';
import { FaExternalLinkAlt } from 'react-icons/fa';

export const USER_INFO = gql`
  query UserInfo{
  getUserById{
    id
    email
    companyName
    phoneNumber
    location
    industry
    firstName
    lastName
    mode
    ssoType
    companylogo
    profileImageUrl
  }
}
`

export const UPDATE_USER_INFO = gql`
  mutation UpadateUserInfo(
    $id: String!, 
    $companyName: String!,
    $phoneNumber: String!,
    $location: String!,
    $industry: String!,
    $FirstName: String!,
    $LastName: String!
    $mode: Boolean
  ){
  updateUser(input: 
  {
    id:$id,
    companyName:$companyName,
    phoneNumber:$phoneNumber,
    location:$location,
    industry:$industry,
    FirstName:$FirstName,
    LastName: $LastName,
    mode: $mode,
}){
    companyName
    phoneNumber
    location
    industry
    updatedAt
  }
}
`

export const UPDATE_PASSWORD = gql`
  mutation UpadatePassword($oldpassword: String!,$newPassword: String!,$id: String!){
  changePassword(input: {oldpassword:$oldpassword,newPassword:$newPassword,id:$id}){
    message
    updatedAt
  }
}
`
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};



const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  orange: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
    width: "100px",
    height: "100px",
    margin: "10px",
  },
}));

function ScrollableTabsButtonForce(props) {
  const permissions = JSON.parse(localStorage.getItem('permissions'))

  const [email, setEmail] = React.useState("");
  const [fname, setFName] = React.useState("");
  const [lname, setLName] = React.useState("");
  const [phone, setPhone] = React.useState("111111111111");
  const [cpassword, setCPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [company, setCompany] = React.useState("");
  const [industry, setIndustry] = React.useState("");
  const [loaction, setLocation] = React.useState("");
  const [billingAddress, setBillingAddress] = React.useState("");

const [showDeleteMyAccount, setshowDeleteMyAccount] = useState(false);
  const [profileChanged, setProfileChanged] = React.useState(false);
  const [companyChanged, setCompanyChanged] = React.useState(false);
  const [passwordChanged, setPasswordChanged] = React.useState(false);
  const [addressChanged, setAdressChanged] = React.useState(false);



  const [passwordValidation, setPasswordValidation] = React.useState(false)
  const [passwordErrors, setPasswordErrors] = React.useState('')

  const [isError, setIsError] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState("")
  const [isSSO, setIsSSO] = React.useState(false)
  const [isReadOnly, setisReadOnly] = useState(true);

  const [mode, setMode] = useState(true)
  var CryptoJS = require("crypto-js");

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    document.title = "Settings"
    setIsSSO(true)
  }, []);

  const [updateUser, { loading: userloading }] = useMutation(UPDATE_USER_INFO, {
    refetchQueries: [USER_INFO],
    onCompleted: () => {
      enqueueSnackbar("Successfully Updated!", {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      })
      LogTrigger("User details updated", "info", window.location.href, "updateUserInfo")

      setOpen(true)
      setisReadOnly(true)
    },
    onError: (err) => {
      enqueueSnackbar(err.message, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      })
      setIsError(true)
      setErrorMessage("Somthing went worng, please try again later")
      setOpen(true)
      setEmail(userdata.getUserById.email)
      setFName(userdata.getUserById.firstName)
      setLName(userdata.getUserById.lastName)
      setPhone(decodeAes(userdata.getUserById.phoneNumber))
      setCompany(userdata.getUserById.companyName)
      setIndustry(userdata.getUserById.industry)
      setLocation(userdata.getUserById.location)
      setBillingAddress(userdata.getUserById.billingAddress)
      setMode(userdata.getUserById.mode)
    }
  });

  const [updatePassword, { data, loading, error }] = useMutation(UPDATE_PASSWORD, {
    onCompleted: () => {
      //setOpen(true)
      props.logout("Password Updated!, please login")
    },
    onError: () => {

      setIsError(true)
      setErrorMessage("Old password doesn't match")
      setOpen(true)
    }
  });




  const valuevalidatePassword = (e) => {
    const p = e.target.value
    const errors = [];
    if (p.length < 8) {
      errors.push("Your password must be at least 8 characters,");
    }
    if (p.search(/[a-z]/i) < 0) {
      errors.push(" at least one letter");
    }
    if (p.search(/[A-Z]/i) < 0) {
      errors.push(" at least one uppercase letter");
    }
    if (p.search(/[0-9]/) < 0) {
      errors.push(" at least one digit");
    }
    if (errors.length > 0) {
      setPasswordErrors(errors.join("\n"))
    }
    setPasswordValidation((errors.length > 0) ? true : false)
  }

  function encodeAes(plaintText) {
    var key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_AES_KEY);
    var encryptedData = CryptoJS.AES.encrypt(plaintText, key, {
      iv: key,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });

    encryptedData = encryptedData.ciphertext.toString();

    return encryptedData

  }

  function decodeAes(encryptedDataStr) {
    var key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_AES_KEY);
    var encryptedHexStr = CryptoJS.enc.Hex.parse(encryptedDataStr);
    var encryptedBase64Str = CryptoJS.enc.Base64.stringify(encryptedHexStr);

    var decryptedData = CryptoJS.AES.decrypt(encryptedBase64Str, key, {
      iv: key,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    decryptedData = decryptedData.toString(CryptoJS.enc.Utf8)
    return decryptedData

  }

  const [open, setOpen] = React.useState(false);
  const role = localStorage.getItem("role")
  const [validPhoneNumber, setvalidPhoneNumber] = React.useState(true);
  const [validLocation, setvalidLocation] = React.useState(true);

  const handleClose = () => {
    setOpen(false)
    setIsError(false)
    setErrorMessage("")
  }
  const handleSave = () => {
    updateUser({ variables: { id: localStorage.getItem("userId"), companyName: company, phoneNumber: encodeAes(phone), location: loaction, industry: industry, billingAddress: billingAddress, FirstName: fname, LastName: lname, mode: mode } });

  }

  const handelTheme = () => {
    if (localStorage.getItem("mode") === "dark") {
      localStorage.setItem("mode", "light")
    }
    else {
      localStorage.setItem("mode", "dark")
    }
    window.location.reload()

  }

  const handleValidatePhoneNumber = (e) => {
    setPhone(e.target.value);
    const reg = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
    setvalidPhoneNumber(reg.test(e.target.value));
  }

  const handleValidateLocation = (e) => {
    setLocation(e.target.value);
    setvalidLocation(/[a-zA-Z]/.test(e.target.value));
  }

  const handleProfileCancel = () => {
    setEmail(userdata.getUserById.email)
    setFName(userdata.getUserById.firstName)
    setLName(userdata.getUserById.lastName)
    setPhone(userdata.getUserById.phoneNumber)
    setProfileChanged(false)
  }
  const handleCompanyCancel = () => {
    setCompany(userdata.getUserById.companyName)
    setIndustry(userdata.getUserById.industry)
    setCompanyChanged(false)
  }

  const handlePasswordSave = () => {
    updatePassword({ variables: { id: localStorage.getItem("userId"), oldpassword: encodeAes(cpassword), newPassword: encodeAes(newPassword) } });
    setCPassword("")
    setNewPassword("")
    setPasswordChanged(false)
  }
  const handleAdressCancel = () => {
    setLocation(userdata.getUserById.location)
    setBillingAddress(userdata.getUserById.billingAddress)
    setAdressChanged(false)
  }

  const { loading: load, error: err, data: userdata } = useQuery(USER_INFO, {
    variables: { userId: localStorage.getItem("userId") },
    onCompleted: () => {
      setEmail(userdata.getUserById.email)
      setFName(userdata.getUserById.firstName)
      setLName(userdata.getUserById.lastName)
      setCompany(userdata.getUserById.companyName)
      setLocation(userdata.getUserById.location)
      setIndustry(userdata.getUserById.industry)
      setPhone(decodeAes(userdata.getUserById.phoneNumber))
    }
  });

  if (userdata) {
    LogTrigger("Profile details loaded", "info", window.location.href, "Profile")
  }
  if (err) {
    LogTrigger(err.message, "error", window.location.href, "Profile")
  }
  if (err) return `Error! ${err.message}`;
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <div>
            {load ? <Skeleton variant="text" width={"100%"} height={200} /> :

              <Paper style={{ textAlign: "center", marginTop: "24px" }} variant={"outlined"}>
                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center" }}>
                  {userdata.getUserById.profileImageUrl === "" ?
                    <Avatar style={{ borderRadius: "50%", margin: "10px", marginTop: "35px", textAlign: "center", width: "120px", height: "120px" }} alt={userdata.getUserById.firstName}> <PersonIcon /> </Avatar>
                    :
                    <Avatar style={{ borderRadius: "50%", margin: "10px", marginTop: "35px", textAlign: "center", width: "120px", height: "120px" }} alt={userdata.getUserById.firstName} src={userdata.getUserById.profileImageUrl} />
                  }
                </div>

                <Typography variant={"h5"}>
                  {userdata.getUserById.firstName} {userdata.getUserById.lastName}
                </Typography>

                <Typography variant={"subtitle"}>
                  {userdata.getUserById.email}
                </Typography>
                <br />
                <Button style={{ margin: "10px" }} component="label" startIcon={<BsPersonBoundingBox />} color="primary">Upload Avatar <CropImage /></Button>

                {permissions[0].delete && <Button disabled={role === "Viewer"} variant='sky' component="label" color="primary">Upload Company Logo <UploadCompanyLogo type="logo" /></Button>}

              </Paper>}

            {/* <Paper style={{ textAlign: "center", marginTop: "24px" }} variant={"outlined"}>




              <Typography variant='h6' style={{ margin: "5px ", fontWeight: "bold", }}> Theme </Typography>
              <Divider />
              {localStorage.getItem("mode") === "dark" ?
                <Button startIcon={<LightMode />} onClick={handelTheme} style={{ marginBottom: "5px" }}>Enable Light Mode</Button>
                :
                <Button startIcon={<DarkMode />} onClick={handelTheme} style={{ marginBottom: "5px" }}>Enable Dark Mode</Button>}
            </Paper> */}


          </div>

        </Grid>





        <Grid item xs={12} sm={8}>
          <Paper variant='outlined' style={{ marginTop: "24px" }}>
            <Grid
              container
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
            >
              <Grid item xs={2} sx={{ p: 1 }}>

                {(localStorage.getItem("access") && JSON.parse(localStorage.getItem("access")).planName && JSON.parse(localStorage.getItem("access")).planName === "free plan" &&
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    fill="#000000"
                    version="1.1"
                    viewBox="0 0 512 512"
                    xmlSpace="preserve"
                  >
                    <linearGradient id="MyGradient3">
                      <stop offset="5%" stop-color="#89f7fe" />
                      <stop offset="95%" stop-color="#5c84f5" />
                    </linearGradient>
                    <path fill={"url(#MyGradient3)"} d="M508.645,18.449c-2.929-2.704-7.133-3.51-10.826-2.085L6.715,204.446c-3.541,1.356-6.066,4.515-6.607,8.264c-0.541,3.75,0.985,7.496,3.995,9.796l152.127,116.747c-0.004,0.116-0.575,0.224-0.575,0.342v83.592c0,3.851,2.663,7.393,6.061,9.213c1.541,0.827,3.51,1.236,5.199,1.236c2.026,0,4.181-0.593,5.931-1.756l56.12-37.367l130.369,99.669c1.848,1.413,4.099,2.149,6.365,2.149c1.087,0,2.186-0.169,3.248-0.516c3.27-1.066,5.811-3.672,6.786-6.974L511.571,29.082C512.698,25.271,511.563,21.148,508.645,18.449z M170.506,321.508c-0.385,0.36-0.7,0.763-1.019,1.163L31.659,217.272L456.525,54.557L170.506,321.508z M176.552,403.661v-48.454l33.852,25.887L176.552,403.661z M359.996,468.354l-121.63-93.012c-1.263-1.77-2.975-3.029-4.883-3.733l-47.29-36.163L480.392,60.86L359.996,468.354z" />

                  </svg>
                )}

                {(localStorage.getItem("access") && JSON.parse(localStorage.getItem("access")).planName && JSON.parse(localStorage.getItem("access")).planName === "Starter" &&
                  <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 128 128">
                    <defs />
                    <linearGradient id="MyGradient3">
                      <stop offset="5%" stop-color="#89f7fe" />
                      <stop offset="95%" stop-color="#5c84f5" />
                    </linearGradient>
                    <path fill={"url(#MyGradient3)"} d="M76.1 91.4V71.7a1 1 0 00-.2-.6l-5.7-6.9V15.4a1.1 1.1 0 00-.1-.4.5.5 0 000-.1l-5.8-10a1 1 0 00-1.7 0l-5.9 10a.5.5 0 000 .1 1.2 1.2 0 00-.1.4v48.8l-5.7 6.9a1 1 0 00-.2.6v19.7a1 1 0 001 1h10.6V95a1 1 0 102 0v-2.7h10.8a1 1 0 001-1zm-17.6-75h9.7v4h-9.7zm4.8-9l4.1 7h-8.1zM52.6 72l4-4.8v23h-4zm11.7 18.3V56.7a1 1 0 10-2 0v33.7h-3.8v-68h9.7v68zm9.8 0h-4v-23l4 4.7z" />
                    <path fill={"url(#MyGradient3)"} d="M59 94.7a1 1 0 00-1 1v10.9a4.2 4.2 0 00-3.3 2.7 1 1 0 00.6 1.3 1 1 0 00.4 0 1 1 0 001-.6 2.2 2.2 0 012.8-1.4 1 1 0 10.7-1.9H60v-11a1 1 0 00-1-1zM68.7 101.2v-5.5a1 1 0 00-2 0v5.5a1 1 0 102 0zM60.2 112.8a1.4 1.4 0 011.3 1 1 1 0 001.9.3 2.5 2.5 0 014.7 1.4 1 1 0 002 0 4.6 4.6 0 00-7.4-3.6 3.4 3.4 0 00-2.5-1.1 1 1 0 000 2zM73.2 109.7a1 1 0 002 0 6.1 6.1 0 00-6.1-6.1 1 1 0 000 2 4.1 4.1 0 014.1 4zM105.2 109a9.8 9.8 0 00-19-3 6.4 6.4 0 00-2.8-.7 6.5 6.5 0 00-6.5 6.5 1 1 0 002 0 4.5 4.5 0 014.5-4.5 4.4 4.4 0 012.8 1 1 1 0 001.6-.6 7.8 7.8 0 0115.4 1.4 1 1 0 002 0zM7.8 123a8 8 0 006.2-.1 1 1 0 00-.8-1.8 6 6 0 01-8-3 6 6 0 012.9-8 9.4 9.4 0 001.5.8 1 1 0 00.4.1 1 1 0 00.4-2 7.5 7.5 0 01-1-13.2 1 1 0 00.1-1.6A4.2 4.2 0 018.1 91a4.2 4.2 0 017.1-3 1 1 0 001 .2 1 1 0 00.6-.6 5.8 5.8 0 0111.3 1.1A7.4 7.4 0 0022 96a1 1 0 002 0 5.4 5.4 0 118.6 4.4 8.9 8.9 0 00-5.4 7 6.6 6.6 0 00-1 0 6.4 6.4 0 00-4.5 1.9 1 1 0 000 1.4 1 1 0 001.4 0 4.4 4.4 0 017.5 3.1 1 1 0 002 0 6.4 6.4 0 00-3.4-5.7 6.8 6.8 0 014.2-5.9 6.8 6.8 0 019.4 5.9 1 1 0 001.4.8 4.8 4.8 0 011.8-.3 5 5 0 015 5 1 1 0 002 0 7 7 0 00-8.4-6.9 8.9 8.9 0 00-8.6-7h-.2a7.4 7.4 0 00-5.7-11.1 7.8 7.8 0 00-14.6-2.9 6.2 6.2 0 00-3.2-.9 6.2 6.2 0 00-5 10 9.4 9.4 0 00-1 13.9 8.1 8.1 0 00-3.3 4A8 8 0 007.8 123zM91.8 119.8a1 1 0 002 0 6.4 6.4 0 00-11-4.5 1 1 0 000 1.4 1 1 0 001.4 0 4.4 4.4 0 013.1-1.3 4.4 4.4 0 014.5 4.4zM125.4 103.3a7.7 7.7 0 00-3.4-6.4 8.1 8.1 0 00.7-3.3A8.3 8.3 0 00107 90a5.8 5.8 0 00-4 10.6 1 1 0 101.1-1.7 3.8 3.8 0 013.2-6.8 1 1 0 001.2-.6 6.3 6.3 0 0112.2 2 6.2 6.2 0 01-1 3.3 1 1 0 000 .8 1 1 0 00.5.6 5.6 5.6 0 011 9.5 8.4 8.4 0 00-2.8-1.6 1 1 0 10-.6 1.8 6.7 6.7 0 01-.4 12.8 1 1 0 00-.7 1.2 1 1 0 001 .8 1.1 1.1 0 00.2 0A8.7 8.7 0 00124 112a8.7 8.7 0 00-1.3-2.8 7.6 7.6 0 002.7-6z" />
                  </svg>
                )}

                {(localStorage.getItem("access") && JSON.parse(localStorage.getItem("access")).planName && JSON.parse(localStorage.getItem("access")).planName === "Premium" &&
                  <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 128 128">
                    <defs />
                    <linearGradient id="MyGradient3">
                      <stop offset="5%" stop-color="#89f7fe" />
                      <stop offset="95%" stop-color="#5c84f5" />
                    </linearGradient>
                    <path fill={"url(#MyGradient3)"} d="M62.6 103.3a1 1 0 00-1.4 0L44 120.8a1 1 0 000 1.4 1 1 0 00.7.3 1 1 0 00.7-.3l17.3-17.3a1 1 0 000-1.5zM58.7 116.3a1 1 0 00.7-.3l7.2-7.2a1 1 0 00-1.4-1.5l-7.2 7.3a1 1 0 00.7 1.7zM55.2 117.4l-2.8 2.8a1 1 0 00.7 1.7 1 1 0 00.7-.3l2.8-2.8a1 1 0 000-1.4 1 1 0 00-1.4 0zM5.9 84.1a1 1 0 00.7.3 1 1 0 00.7-.3l17.4-17.3a1 1 0 10-1.5-1.4L6 82.7a1 1 0 000 1.4zM13.4 70l7.3-7.2a1 1 0 10-1.5-1.4L12 68.6a1 1 0 00.7 1.7 1 1 0 00.7-.3zM7.8 75.6l2.8-2.8a1 1 0 000-1.4 1 1 0 00-1.4 0l-2.8 2.8a1 1 0 000 1.4 1 1 0 00.7.3 1 1 0 00.7-.3zM68 33.6L27.1 40a1 1 0 00-.5.3L20 46.8a1 1 0 000 1.5l16.6 16.6-4.7 4.7a1 1 0 000 1.5l11.5 11.4-4.6 4.6a1 1 0 00.7 1.7 1 1 0 00.7-.3l4.6-4.6L57 96a1 1 0 001.4 0l4.7-4.7 16.7 16.6a1 1 0 001.4 0l6-6 .5-.5a1 1 0 00.3-.6L94.4 60l20.3-20.3a22.8 22.8 0 006-10.3.6.6 0 000-.2 22 22 0 00.5-3.4l1.1-11.3a8.1 8.1 0 00-8.8-8.8l-11.3 1a22.7 22.7 0 00-3.4.6h-.1a22.8 22.8 0 00-10.4 6zm-23.9 24L28.4 41.8 65.6 36zm-22-10l4.6-4.6 16 16-4.6 4.5zm58.4 58.2l-16-16 4.5-4.5 16 16zm5.6-6.3L70.5 84 92 62.4zm16.3-90.8l11.3-1.1a6.1 6.1 0 016.7 6.7l-1.1 11.3-.2 1.3-18-18 1.3-.2zm-3.6.7l19.8 19.8a21 21 0 01-5.3 9L57.7 94 46.3 82.5 75 53.7a1 1 0 000-1.4 1 1 0 00-1.5 0L45 81.1 34 70.3l10.7-10.6 45-45a21 21 0 019-5.3zM10.9 120.4a3.3 3.3 0 001.4-.3l19.5-9.1A11.2 11.2 0 1017 96.2l-9.1 19.5a3.3 3.3 0 003 4.7zm-1.2-3.9l9-19.5a9.1 9.1 0 016.8-5.2 10.2 10.2 0 011.7-.1 9.2 9.2 0 019 10.8 9.1 9.1 0 01-5.2 6.7l-19.5 9a1.3 1.3 0 01-1.8-1.7z" />
                    <path fill={"url(#MyGradient3)"} d="M96.2 42.3a10.5 10.5 0 10-7.4-3 10.4 10.4 0 007.4 3zm-6-16.5a8.5 8.5 0 110 12 8.4 8.4 0 010-12z" />
                  </svg>
                )}

                {(localStorage.getItem("access") && JSON.parse(localStorage.getItem("access")).planName && JSON.parse(localStorage.getItem("access")).planName === "Enterprise" && <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 128 128">
                  <defs />
                  <linearGradient id="MyGradient3">
                    <stop offset="5%" stop-color="#89f7fe" />
                    <stop offset="95%" stop-color="#5c84f5" />
                  </linearGradient>
                  <path fill={"url(#MyGradient3)"} d="M38.6 53.2a14.6 14.6 0 0010.6-24.6 1 1 0 00-.7-.3 1.1 1.1 0 00-.7.3l-8.9 8.9-1.7-1.7a1 1 0 00-1.4 1.4l1.7 1.7-9 8.9a1 1 0 000 1.4 14.5 14.5 0 0010.1 4zm9.9-22.4a12.6 12.6 0 01-17.7 17.7z" />
                  <path fill={"url(#MyGradient3)"} d="M2.7 102.7a1 1 0 00.3.7L24.5 125a1 1 0 00.7.3 1 1 0 00.7-.3l12.4-12.3a.8.8 0 00.4-.5l12.1-12a1 1 0 00.3-.2.9.9 0 00.2-.3l12.3-12.3a1 1 0 000-1.4l-8.4-8.4 1.5-1.5 11.8 11.9 3.7 6.4.1.1a1 1 0 00.1.2l5.1 5.1a1 1 0 00.7.3 1 1 0 00.7-.3L99.7 79a1 1 0 000-1.4l-5-5.1a1 1 0 00-.2-.1h-.1l-6.5-3.8-11.8-11.9 1.4-1.4 8.4 8.3a1 1 0 00.7.3 1 1 0 00.7-.3L125 26a1 1 0 000-1.4L103.5 3a1 1 0 00-1.4 0L89.7 15.4a.8.8 0 00-.4.5L77.2 28a1 1 0 00-.3.2.9.9 0 00-.2.3L64.4 40.8a1 1 0 000 1.4l8.4 8.4-1.5 1.4-11.4-11.4a1 1 0 00-1.4 0l-18 17.9a1 1 0 000 1.4L52 71.3l-1.4 1.4-8.4-8.3a1 1 0 00-1.4 0L3 102a1 1 0 00-.3.7zM29 79l9.3 9.3L27 99.5l-9.4-9.3zm10.7 10.8l9.3 9.3-11.1 11.1-9.3-9.3zm38.7 7.8L74.6 94 94 74.6l3.7 3.7zm-4.9-5.3l-2.8-5 16.8-16.7 5 2.8zm-4-6.5L67 83.6 78.7 72a1 1 0 000-1.4 1 1 0 00-1.4 0L65.7 82.2 46.3 62.8l16.5-16.5 19.4 19.4-1.7 1.7a1 1 0 101.4 1.4l1.7-1.7 2.2 2.2zm20.8-68l9.3 9.3-11 11.1L79 29zm10.7 10.8l9.4 9.3L99.2 49l-9.4-9.3zm1.9-23.4l20 20-11.1 11.2-9.3-9.3 10-10a1 1 0 000-1.4 1 1 0 00-1.5 0l-10 10-9.3-9.3zM77.7 30.3l9.3 9.3-9.6 9.7a1 1 0 000 1.4 1 1 0 001.4 0l9.6-9.7 9.3 9.4-11 11.1-20.2-20zM74.2 52l2 2-1.5 1.4-2-2zm-15-9.2l2.2 2.2L45 61.4l-2.2-2.2zm-5.8 30l1.9 2-1.5 1.4-2-2zm-12-6.2l20 20-11 11.2-9.4-9.3 9.6-9.7a1 1 0 00-1.4-1.4L39.6 87l-9.3-9.3zm-25 25l9.2 9.4-10 10a1 1 0 000 1.4 1 1 0 001.5 0l10-10 9.3 9.3-11.2 11.2-20-20zM43.6 28.2a1 1 0 00-.3-1.4 11.9 11.9 0 00-16.5 16.5 1 1 0 00.9.5 1 1 0 00.5-.2 1 1 0 00.3-1.4 9.9 9.9 0 0113.7-13.7 1 1 0 001.4-.3z" />
                  <path fill={"url(#MyGradient3)"} d="M38.6 32.3a1 1 0 10.9-1.8 6.7 6.7 0 00-9 9 1 1 0 00.9.5 1 1 0 00.9-1.4 4.7 4.7 0 016.3-6.3z" />
                </svg>)}

              </Grid>


              <Grid item xs={8}>
                <Typography variant={"subtitle"} style={{ marginTop: "10px" }}>
                  You are on {" "}
                  <b>
                    {(localStorage.getItem("access") && JSON.parse(localStorage.getItem("access")).planName && JSON.parse(localStorage.getItem("access")).planName !== "free plan" ?
                      JSON.parse(localStorage.getItem("access")).planName : "Hobby plan")}

                    {" "}{localStorage.getItem("access") && JSON.parse(localStorage.getItem("access")).planName !== "free plan" && "plan"}
                  </b>
                </Typography>
              </Grid>
              <Grid item xs={2}>
                {localStorage.getItem("access") && JSON.parse(localStorage.getItem("access")).planName !== "Enterprise" && <UpgradePlan invite={true} />}
              </Grid>
            </Grid>
          </Paper>


          {load ? <Skeleton variant="text" width={"100%"} height={200} /> :
            <Paper variant='outlined' style={{ marginTop: "24px" }} >





              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                style={{ margin: "15px 0px" }}
              >

                <Typography variant='h6' style={{ marginLeft: "15px", fontWeight: "bold" }}> Personal Information </Typography>

                <div>

                  <Button sx={{ mr: 2 }} size='small' disableElevation onClick={() => {
                    setisReadOnly(!isReadOnly);
                    if (!isReadOnly) {

                      setEmail(userdata.getUserById.email)
                      setFName(userdata.getUserById.firstName)
                      setLName(userdata.getUserById.lastName)
                      setPhone(decodeAes(userdata.getUserById.phoneNumber))
                      setCompany(userdata.getUserById.companyName)
                      setIndustry(userdata.getUserById.industry)
                      setLocation(userdata.getUserById.location)
                      setCompanyChanged(false)
                    }

                  }} color="primary" variant={isReadOnly ? 'contained' : 'outlined'} endIcon={isReadOnly ? <EditIcon/> : <CloseIcon />}>{isReadOnly ? "Edit" : "Cancel"}</Button>
                </div>
              </Grid>
              <Divider />
              <Grid container spacing={2} style={{ padding: "15px" }}>

                <Grid item xs={12} sm={6}>
                  {isReadOnly ? <Typography variant='body2' style={{ color: "grey " }}> First Name:<Typography color="text.primary" variant="body2" gutterBottom> {fname}</Typography></Typography> :
                    <TextField


                      autoComplete="fname"
                      name="firstName"
                      variant="outlined"
                      required
                      disabled={load}
                      autoFocus
                      value={fname}
                      fullWidth
                      id="firstName"
                      label="First Name"
                      onChange={e => { setFName(e.target.value); setProfileChanged(true) }}

                    />}
                </Grid>
                <Grid item xs={12} sm={6}>

                  {/* <Typography variant='body2' style={{ marginLeft: "15px", color: "grey" }}> Jia </Typography> */}

                  {isReadOnly ? <Typography variant='body2' style={{ color: "grey " }}> Last Name:<Typography variant="body2" color="text.primary" gutterBottom> {lname}</Typography></Typography> :
                    <TextField

                      variant="outlined"
                      value={lname}
                      required
                      fullWidth
                      id="lastName"
                      label="Last Name"
                      name="lastName"
                      disabled={load}

                      autoComplete="lname"
                      onChange={e => {
                        setLName(e.target.value);
                        setProfileChanged(true)
                      }}
                    />}
                </Grid>
                <Grid item xs={12} sm={6}>
                  {isReadOnly ?
                    <Typography variant='body2' style={{ color: "grey " }}> Phone Number:<Typography color="text.primary" variant="body2" gutterBottom> {phone}</Typography></Typography> :
                    <>
                      <PhoneInput
                        inputProps={{
                          name: 'phone',
                          required: true,
                        }}
                        country={'us'}
                        value={phone}
                        enableSearch={true}
                        disabled={load}
                        onChange={phone => setPhone(phone)}

                        isValid={(value, country) => {
                          if (value.match(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/)) {
                            setvalidPhoneNumber(false)
                            return 'Invalid value: ' + value + ', ' + country.name;
                          }
                          else {
                            setvalidPhoneNumber(true)
                            return true;
                          }
                        }}
                      />
                      {/* <TextField
                        variant="outlined"
                        required
                        value={phone}

                        fullWidth
                        id="phone"
                        label="Phone Number"
                        name="phone"
                        disabled={load}
                        placeholder={"+91 8692863372"}

                        onChange={e => handleValidatePhoneNumber(e)}
                        helperText={!validPhoneNumber && "Invalid Phone Number "}
                        error={!validPhoneNumber}
                      /> */}
                    </>
                  }
                </Grid>



                <Grid item xs={12} sm={6}>
                  {isReadOnly ? <Typography variant='body2' style={{ color: "grey " }}> Company Name:<Typography variant="body2" color="text.primary" gutterBottom> {company}</Typography></Typography> :
                    <TextField

                      autoComplete="fname"
                      style={{ boderRaduis: "15px" }}
                      name="firstName"
                      variant="outlined"
                      required
                      disabled={load || !permissions[0].delete}
                      value={company}
                      fullWidth
                      id="firstName"
                      label="Company Name"

                      onChange={e => { setCompany(e.target.value); setCompanyChanged(true) }}
                    />}
                </Grid>
                <Grid item xs={12} sm={6}>
                  {isReadOnly ? <Typography variant='body2' style={{ color: "grey " }}> Industry:<Typography variant="body2" color="text.primary" gutterBottom> {industry}</Typography></Typography> :
                    <TextField

                      variant="outlined"
                      required
                      value={industry}
                      fullWidth
                      id="lastName"
                      disabled={load}
                      label="Industry Name"
                      name="lastName"
                      autoComplete="lname"
                      onChange={e => { setIndustry(e.target.value); setCompanyChanged(true) }}
                    />}
                </Grid>
                {/* 
                {companyChanged &&
                  <Grow in={companyChanged}>
                    <Grid item xs={12}>
                      <Button
                        disabled={fname === "" ? true : lname === "" ? true : phone === "" ? true : company === "" ? true : industry === "" ? true : loaction === "" ? true : false}

                        onClick={e => {
                          handleSave();

                          setCompanyChanged(false);
                        }} color="primary" variant='contained' disableElevation>Save</Button>

                      <Button onClick={handleCompanyCancel} variant="outlined" style={{ backgroundColor: "white", color: "#5F87FC", marginLeft: "10px" }}>Cancel</Button>
                    </Grid>
                  </Grow>
                } */}





                {!isSSO && <><Grid item xs={12}>
                  <h2> Password</h2>
                </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField

                      autoComplete="fname"
                      style={{ boderRaduis: "15px" }}
                      name="firstName"
                      variant="outlined"
                      required
                      value={cpassword}
                      fullWidth
                      type="password"
                      id="firstName"
                      label="Current Password"
                      onChange={e => { setCPassword(e.target.value) }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      required
                      value={newPassword}
                      fullWidth
                      id="lastName"
                      error={passwordValidation}
                      label="New Password"
                      type="password"
                      helperText={passwordValidation && passwordErrors}
                      name="lastName"
                      autoComplete="lname"
                      onChange={e => { setNewPassword(e.target.value); setPasswordChanged(true); valuevalidatePassword(e) }}
                    />
                  </Grid></>}

                {passwordChanged &&
                  <Grow in={passwordChanged}>
                    <Grid item xs={12}><Button disabled={passwordValidation} onClick={e => { handlePasswordSave(); }} style={{ backgroundColor: "#5F87FC", color: "white" }}>Update Password</Button>
                    </Grid>
                  </Grow>
                }




                {permissions && permissions[0].delete && <>
                  {/* <Grid item xs={12}>



                  <Typography variant='body2' style={{ marginLeft: "0px" }}> Address</Typography>
                </Grid> */}

                  <Grid item xs={12} sm={6}>
                    {isReadOnly ? <Typography variant='body2' style={{ color: "grey " }}> Location:<Typography color="text.primary"
                      variant="body2" gutterBottom> {loaction}</Typography></Typography> :
                      <TextField

                        autoComplete="fname"
                        style={{ boderRaduis: "15px" }}
                        name="firstName"
                        variant="outlined"
                        required
                        disabled={load}
                        value={loaction}
                        fullWidth
                        id="firstName"
                        label="Location"
                        onChange={e => handleValidateLocation(e)}
                        error={!validLocation}
                        helperText={!validLocation && "Invalid Location"}
                      // onChange={e => { setLocation(e.target.value); setAdressChanged(true) }}

                      />}
                  </Grid>
                </>}

                {/* {addressChanged &&
                  <Grow in={addressChanged}>
                    <Grid item xs={12}>
                      <Button
                        disabled={fname === "" ? true : lname === "" ? true : phone === "" ? true : company === "" ? true : industry === "" ? true : loaction === "" ? true : false}

                        onClick={e => {
                          handleSave();

                          setAdressChanged(false);
                        }} color="primary" variant='contained' disableElevation>Save</Button>

                      <Button onClick={handleAdressCancel} variant="outlined" style={{ backgroundColor: "white", color: "#5F87FC", marginLeft: "10px" }}>Cancel</Button>
                    </Grid></Grow>
                } */}

                <Grid item xs={12}>
                  {!isReadOnly && <Button


                    disabled={fname === "" ? true : lname === "" ? true : phone === "" ? true : company === "" ? true : industry === "" ? true : loaction === "" ? true : userloading ? true : !validLocation ? true : !validPhoneNumber ? true : false}

                    onClick={e => {
                      handleSave();


                    }} color="primary" variant='contained' disableElevation> {userloading ? "Loading.." : "Save"} </Button>}
                </Grid>

              </Grid>

            </Paper>

          }


    



<Button onClick={()=> setshowDeleteMyAccount(!showDeleteMyAccount)} startIcon={<MoreHoriz/>}>More </Button>
{showDeleteMyAccount && <Paper variant='outlined' sx={{p:2, borderRadius:"12px"}}>
<Button color='error' endIcon={<FaExternalLinkAlt/>} onClick={()=>window.location = "https://forms.gle/Tq9oyEZ6RZVRUb1G7" } size='small'>Delete my account</Button>
</Paper>}




        </Grid>













      </Grid>
    </div>
  );
}


const mapStateToProps = ({ authReducer }) => {
  return { authReducer }
}
export default connect(mapStateToProps, { logout })(ScrollableTabsButtonForce)