// ExpiredPlanAlert.js
import React from 'react';
import { Box, Typography, Button, Paper, Divider } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { BiSolidDollarCircle } from 'react-icons/bi';
import { CONFIG_APP_BASE_COMPANY } from '../configApp';

const ExpiredPlanAlert = () => {
    let history = useHistory();
    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="90vh"
        >
            <Paper elevation={0} sx={{ boxShadow: "rgba(58, 59, 69, 0.0.5) 0px 0.15rem 1.75rem 0px", borderRadius: "24px", padding: 4, textAlign: 'center' }}>
                <Box display="flex" justifyContent="center" mb={2}>
                    <WarningIcon color="warning" sx={{ fontSize: 40 }} />
                </Box>
                <Typography variant="h6" gutterBottom>
                    Oops, Your plan has expired
                </Typography>
                <Typography variant="body1" mb={2}>
                    To continue accessing the dashboard, please upgrade your plan.
                </Typography>
                <Button size='large' sx={{ mb: 2 }} onClick={() => history.push("/plans?freetrail=expired")} variant="contained" color="primary">
                    Upgrade Now
                </Button>

                <Divider />


                {CONFIG_APP_BASE_COMPANY === "nife" && <div>
                    <Typography my={2} onClick={() => history.push("/Cost-Monitoring")} sx={{ textTransform: "none", cursor: "pointer" }} color="primary">
                        What you can still access?
                    </Typography>

                    <Button startIcon={<BiSolidDollarCircle />} onClick={() => history.push("/Cost-Monitoring")} sx={{ textTransform: "capitalize", cursor: "pointer", mb: 2, boxShadow: "rgba(17, 12, 46, 0.04) 0px 20px 30px 0px", borderBottom: "2px solid #160044", borderRadius: "0px" }} color="primary">
                        Cost monitoring
                    </Button>
                </div>}


            </Paper>
        </Box>
    );
};

export default ExpiredPlanAlert;
