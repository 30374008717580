import React, { useState, useEffect } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
} from 'recharts';
import { Table, TableBody, TableCell, TableHead, TableRow, Grid, Paper, Typography, Box, Skeleton } from '@mui/material';
import { styled } from '@mui/system';
import { CONFIG_APP_UPTIME_METRICS_BASE_URL } from '../configApp';

// Styled components for a consistent design
const ChartContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  textAlign: 'center',
  borderRadius: theme.spacing(1),
}));

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  fontWeight: 600,
  
}));

const Description = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  opacity: 0.8,
}));

const parseDnsData = (response) => {
  const zones = response.data.viewer.zones[0];
  return {
    coloName: zones.coloName.map((item) => ({
      metric: item.dimensions.metric,
      count: item.count,
    })),
    ipVersion: zones.ipVersion.map((item) => ({
      version: item.dimensions.metric,
      count: item.count,
    })),
    queryType: zones.queryType.map((item) => ({
      type: item.dimensions.metric,
      count: item.count,
    })),
    protocol: zones.protocol.map((item) => ({
      protocol: item.dimensions.metric,
      count: item.count,
    })),
    queryName: zones.queryName.map((item) => ({
      name: item.dimensions.metric,
      count: item.count,
    })),
    destinationIP: zones.destinationIP.map((item) => ({
      metric: item.dimensions.metric,
      count: item.count,
    })),
    responseCode: zones.responseCode.map((item) => ({
      metric: item.dimensions.metric,
      count: item.count,
    })),
    sourceIP: zones.sourceIP.map((item) => ({
      metric: item.dimensions.metric,
      count: item.count,
    })),
  };
};

const DnsMetricsDashboard = ({ appName }) => {
  const [dnsData, setDnsData] = useState(null);

  useEffect(() => {
    // Calculate the current date and 24 hours ago dynamically
    const currentDate = new Date().toISOString(); // current date in ISO format
    const startDate = new Date(new Date().setDate(new Date().getDate() - 1)).toISOString(); // 24 hours ago

    // Prepare the request payload with dynamic dates
    const requestBody = {
      appName: appName ? appName : "",
      startDatetime: startDate,
      endDatetime: currentDate,
      zoneTag: "b032a57f01eac20ad3c4e8ba2a05bb19",
    };

    // Fetch data from the API
    const fetchData = async () => {
      try {
        const response = await fetch(`${CONFIG_APP_UPTIME_METRICS_BASE_URL}/fetch-dns-analytics`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        });
        const result = await response.json();
        setDnsData(result);
      } catch (error) {
        console.error('Error fetching DNS data:', error);
      }
    };

    fetchData();
  }, []);

  if (!dnsData) {
    return <Skeleton height={150} />;
  }

  // Parse the DNS data
  const data = parseDnsData(dnsData);

  // Colors for pie charts
  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#FF5A5F'];

  return (
    <div style={{ padding: '20px' }}>
      <Grid container spacing={3}>
        {/* Bar Chart for ColoName */}
        <Grid item xs={12} md={6}>
          <ChartContainer variant='outlined'>
            <Title variant='h6'>Counts by ColoName</Title>
            <Description variant='body2'>This chart shows the distribution of DNS query counts across different colocation centers, helping identify heavily used locations.</Description>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={data.coloName}>
                <XAxis dataKey="metric" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="count" fill="#42A5F5" />
              </BarChart>
            </ResponsiveContainer>
          </ChartContainer>
        </Grid>

        {/* Pie Chart for IP Version */}
        <Grid item xs={12} md={6}>
          <ChartContainer variant='outlined'>
            <Title variant='h6'>IP Version Distribution</Title>
            <Description variant='body2'>Visualizes the proportion of DNS queries using IPv4 versus IPv6, highlighting the adoption of modern IP protocols.</Description>
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={data.ipVersion}
                  dataKey="count"
                  nameKey="version"
                  cx="50%"
                  cy="50%"
                  outerRadius={100}
                  label={(entry) => (entry.version === 4 ? 'IPv4' : 'IPv6')}
                >
                  {data.ipVersion.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </ChartContainer>
        </Grid>

        {/* Bar Chart for Destination IP */}
        <Grid item xs={12} md={6}>
          <ChartContainer variant='outlined'>
            <Title variant='h6'>Counts by Destination IP</Title>
            <Description variant='body2'>Shows the frequency of DNS queries for each destination IP, allowing you to identify popular target IPs.</Description>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={data.destinationIP}>
                <XAxis dataKey="metric" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="count" fill="#8884d8" />
              </BarChart>
            </ResponsiveContainer>
          </ChartContainer>
        </Grid>

        {/* Pie Chart for Protocol */}
        <Grid item xs={12} md={6}>
          <ChartContainer variant='outlined'>
            <Title variant='h6'>Protocol Distribution</Title>
            <Description variant='body2'>Displays the breakdown of DNS queries by protocol (e.g., UDP vs. TCP) to understand protocol usage trends.</Description>
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={data.protocol}
                  dataKey="count"
                  nameKey="protocol"
                  cx="50%"
                  cy="50%"
                  outerRadius={100}
                  label
                >
                  {data.protocol.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </ChartContainer>
        </Grid>

        {/* Query Name Table */}
        {/* <Grid item xs={12} md={6}>
          <ChartContainer variant='outlined'>
            <Title variant='h6'>Query Names and Counts</Title>
            <Description variant='body2'>Lists all DNS query names with their respective counts, useful for understanding query distribution.</Description>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Query Name</TableCell>
                  <TableCell>Count</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.queryName.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.count}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </ChartContainer>
        </Grid> */}

        {/* Source IP Table */}
        {/* <Grid item xs={12} md={6}>
          <ChartContainer variant='outlined'>
            <Title variant='h6'>Source IPs and Counts</Title>
            <Description variant='body2'>Lists all source IPs and the number of DNS queries originating from them, providing insights into traffic sources.</Description>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Source IP</TableCell>
                  <TableCell>Count</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.sourceIP.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.metric}</TableCell>
                    <TableCell>{row.count}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </ChartContainer>
        </Grid> */}
      </Grid>
    </div>
  );
};

export default DnsMetricsDashboard;
