import React from 'react'
import DNSmetrics from './DNSmetrics'
import DnsMetricsDashboard from './DNSmetrics2'
import data from "./data2.json"
export default function DNSmetricsLayout({appName}) {
  return (
    <div>
        <DNSmetrics appName={appName} />
        <DnsMetricsDashboard appName={appName} dnsData={data}/>
    </div>
  )
}
