import Button from '@mui/material/Button'
import Slider from '@mui/material/Slider'
import Typography from '@mui/material/Typography'
import withStyles from '@mui/styles/withStyles'
import React, { useCallback, useState } from 'react'
import Cropper from 'react-easy-crop'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import { useSnackbar } from 'notistack';  // Import useSnackbar
import axios from 'axios'
import { styles } from './styles'
import { CONFIG_APP_BASE_API } from '../../configApp'


function Demo({ classes }) {
  const [imageSrc] = React.useState(null)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [open, setOpen] = React.useState(true);

  // Integrate enqueueSnackbar from notistack
  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    setOpen(false);
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    // handle crop completion logic here if needed
  }, []);

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      handleSubmit(file);
    }
  }

  const handleSubmit = (file) => {
    var formdata = new FormData();
    formdata.append("photo", file);

    let config = {
      method: 'post',
      url: `${CONFIG_APP_BASE_API}/api/v1/profileImage?userId=${localStorage.getItem("userId")}&type=logo`,
      headers: { 
        'Content-Type': 'multipart/form-data;boundary=----WebKitFormBoundaryyrV7KO0BoCBuDbTL', 
        'Authorization': `Bearer ${localStorage.getItem("token")}`, 
      },
      data: formdata,
    };

    axios(config)
      .then((res) => {
        setOpen(false);
        enqueueSnackbar('Successfully updated profile image!', { 
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });

        // Wait for 2 seconds and then refresh the page
        setTimeout(() => {
          window.location.reload();  // Perform hard refresh
        }, 10000);  // 2 seconds delay
      })
      .catch((err) => {
        enqueueSnackbar('Something went wrong, try again.', { 
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      });
  };

  return (
    <div>
      {imageSrc && (
        <Dialog
          open={open}
          fullWidth={true}
          maxWidth={"md"}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <div className={classes.cropContainer}>
              <Cropper
                image={imageSrc}
                crop={crop}
                rotation={rotation}
                zoom={zoom}
                aspect={1}
                cropShape="round"
                onCropChange={setCrop}
                onRotationChange={setRotation}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            </div>
            <div className={classes.controls}>
              <div className={classes.sliderContainer}>
                <Typography
                  variant="overline"
                  classes={{ root: classes.sliderLabel }}
                >
                  Zoom
                </Typography>
                <Slider
                  value={zoom}
                  min={1}
                  max={3}
                  step={0.1}
                  aria-labelledby="Zoom"
                  classes={{ root: classes.slider }}
                  onChange={(e, zoom) => setZoom(zoom)}
                />
              </div>
              <Button
                onClick={handleClose}
                variant="contained"
                disableElevation
                classes={{ root: classes.cropButton }}
              >
                Close
              </Button>
              <Button
                onClick={handleSubmit}
                variant="contained"
                color="primary"
                disableElevation
                classes={{ root: classes.cropButton }}
              >
                Save
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      )}
      <input type="file" hidden={true} onChange={onFileChange} accept="image/*" />
    </div>
  );
}

export const CropImage = withStyles(styles)(Demo);
