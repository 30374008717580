import React, { useEffect } from 'react';
import { Backdrop, Grid, Fade, Typography } from '@mui/material';

// Function to format milliseconds to minutes and seconds
function millisToMinutesAndSeconds(millis) {
    return (millis / 1000).toFixed(0) + " seconds";
  }

const DeploymentSuccessModal = ({ showAppDeployed, appTime, onClose }) => {
  // Automatically close after 5 seconds if the modal is shown
  useEffect(() => {
    if (showAppDeployed) {
      const timer = setTimeout(() => {
        onClose(); // Trigger the parent function to close the modal
      }, 1500);

      // Cleanup the timer if the component unmounts
      return () => clearTimeout(timer);
    }
  }, [showAppDeployed, onClose]);

  return (
    showAppDeployed && (
      <Backdrop sx={{ bgcolor: "rgba(0, 0, 0, 0.87)", color: "white" }} open={true}>
        <Grid container direction="column" justifyContent="center" alignItems="center">
          <Grid item>
            <img
              src={"/deployed.gif"}
              alt="deployed"
              style={{ width: "100%", height: "200px", minWidth: "100px", marginBottom: "5px", maxWidth: "800px" }}
            />
          </Grid>
          <Grid item>
            <Fade in={true}>
              <Typography variant='h1' sx={{ fontSize: "2rem", fontWeight: "bold" }}>
                Deployed Successfully in <span style={{color:"#2dd881"}}>{millisToMinutesAndSeconds(appTime)} </span>! 🎉
              </Typography>
            </Fade>
          </Grid>
        </Grid>
      </Backdrop>
    )
  );
};

export default DeploymentSuccessModal;
