import React, { useEffect, useState } from 'react';
import axios from 'axios'; // Make sure axios is installed
import { Box, Grid, Card, CardContent, Typography, Paper, Button, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { XAxis, YAxis, CartesianGrid, Tooltip, PieChart, Pie, Cell, Legend, BarChart, Bar, AreaChart, Area } from 'recharts';
import { CONFIG_APP_UPTIME_METRICS_BASE_URL } from '../configApp';
import { DateRange, Download } from '@mui/icons-material';
import { BiSolidTraffic } from 'react-icons/bi';

// Utility to format bytes
const formatBytes = (bytes) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`;
};

// 404 Page Not Found component
const PageNotFound = () => (
    <Box textAlign="center" padding={3}>
        <Typography variant="h4">404 - Page Not Found</Typography>
        <Typography variant="body1">You do not have access to this page.</Typography>
    </Box>
);

const TrafficDashboard = () => {
    const [data, setData] = useState(null); // Data will be fetched from the API
    const [loading, setLoading] = useState(true); // Loading state
    const [error, setError] = useState(null); // Error state
    const [userId, setUserId] = useState(localStorage.getItem('userId')); // Get userId from localStorage


    useEffect(() => {
        if (userId !== '326') {
            // If user is not authorized, redirect to 404 page

            return;
        }

        const currentDate = new Date();
        const pastDate = new Date();
        pastDate.setMonth(currentDate.getMonth() - 1); // Set to 1 month ago

        // Format the dates in the required format (yyyy-mm-dd)
        const formatDate = (date) => date.toISOString().split('T')[0];

      

        // Fetch the data only if user is authorized
        const fetchData = async () => {
            try {
                const sinceDate = formatDate(pastDate);
                const untilDate = formatDate(currentDate);

                const response = await axios.post(`${CONFIG_APP_UPTIME_METRICS_BASE_URL}/fetch-zone-analytics`, {
                    zoneTag: 'b032a57f01eac20ad3c4e8ba2a05bb19', // example zoneTag
                    since: sinceDate,
                    until: untilDate,
                });

                // Assuming response.data contains the correct structure
                setData(response.data.data.viewer.zones[0].zones);
            } catch (error) {
                setError('Failed to fetch data');
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };


        fetchData();
    }, [userId,]); // Empty dependency array means this will run only once when the component mounts

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    // Aggregate data for charts and summaries
    const totalRequests = data.reduce((sum, item) => sum + item.sum.requests, 0);
    const totalBytes = data.reduce((sum, item) => sum + item.sum.bytes, 0);
    const uniqueVisitors = data.reduce((sum, item) => sum + item.uniq.uniques, 0);
    const cacheEfficiency = data.reduce((sum, item) => sum + item.sum.cachedRequests, 0) / totalRequests;
    const threats = data.reduce((sum, item) => sum + item.sum.threats, 0);

    const trafficByDay = data.map((item) => ({
        time: new Date(item.dimensions.timeslot).toLocaleDateString(),
        requests: item.sum.requests,
        cachedRequests: item.sum.cachedRequests,
        unCachedRequests: item.sum.requests - item.sum.cachedRequests,
        totalBytes: item.sum.bytes,
        cachedBytes: item.sum.cachedBytes,
        unCachedBytes: item.sum.bytes - item.sum.cachedBytes,
        uniques: item.uniq.uniques,
    }));

    const responseStatus = Object.entries(
        data.reduce((acc, item) => {
            item.sum.responseStatusMap.forEach((status) => {
                acc[status.key] = (acc[status.key] || 0) + status.requests;
            });
            return acc;
        }, {})
    ).map(([key, value]) => ({ status: key, requests: value }));

    const sslDistribution = Object.entries(
        data.reduce((acc, item) => {
            item.sum.clientSSLMap.forEach((ssl) => {
                acc[ssl.key] = (acc[ssl.key] || 0) + ssl.requests;
            });
            return acc;
        }, {})
    ).map(([key, value]) => ({ ssl: key, requests: value }));

    const trafficByCountry = Object.entries(
        data.reduce((acc, item) => {
            item.sum.countryMap.forEach((country) => {
                acc[country.key] = (acc[country.key] || 0) + country.requests;
            });
            return acc;
        }, {})
    ).map(([key, value]) => ({ country: key, requests: value }));

    // Colors for Pie Chart
    const pieColors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
    const areaColors = ['#8884d8', '#82ca9d', '#ffc658', '#ff8042'];
    const barColors = ['#ff7300', '#82ca9d'];

    // Function to export data as JSON
    const exportToJson = () => {
        const exportData = {
            totalRequests,
            totalBytes,
            uniqueVisitors,
            cacheEfficiency,
            threats,
            trafficByDay,
            responseStatus,
            sslDistribution,
            trafficByCountry,
        };
        const blob = new Blob([JSON.stringify(exportData, null, 2)], { type: 'application/json' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'traffic_data.json';
        link.click();
    };

    return (
        <Box padding={3}>
        
        <Grid
                container
                direction="row"
                sx={{
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >

<Typography variant='h1' sx={{fontSize:"2rem",my: 4}}>Http Trafic Dashboard</Typography>
            <Box>
            <Grid
                container
                direction="row"
                sx={{
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
            {/* <Button sx={{borderRadius:"24px",my:4,mx:2}} startIcon={<DateRange/>} variant="text" color="primary" onClick={exportToJson}>
                       select date
                    </Button> */}

          <Button sx={{boxShadow:"rgba(58, 59, 69, 0.15) 0px 0.15rem 1.75rem 0px",borderRadius:"24px",my:4}} endIcon={<Download/>} variant="contained" color="primary" onClick={exportToJson}>
                        Export Data as JSON
                    </Button>
            
                 </Grid>
            </Box>
               
              
                  
              
            </Grid>
            <Grid container spacing={3}>
                {/* Summary Cards */}
                <Grid item xs={12} md={3}>
                    <Paper variant='outlined' sx={{borderRadius:"12px", boxShadow: "rgba(17, 12, 46, 0.04) 0px 20px 30px 0px",}}>
                        <CardContent>
                            <Typography variant="h6">Total Requests</Typography>
                            <Typography variant="h4">{totalRequests}</Typography>
                        </CardContent>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Paper variant='outlined' sx={{borderRadius:"12px", boxShadow: "rgba(17, 12, 46, 0.04) 0px 20px 30px 0px",}}>
                        <CardContent>
                            <Typography variant="h6">Total Data Transferred</Typography>
                            <Typography variant="h4">{formatBytes(totalBytes)}</Typography>
                        </CardContent>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Paper variant='outlined' sx={{borderRadius:"12px", boxShadow: "rgba(17, 12, 46, 0.04) 0px 20px 30px 0px",}}>
                        <CardContent>
                            <Typography variant="h6">Unique Visitors</Typography>
                            <Typography variant="h4">{uniqueVisitors}</Typography>
                        </CardContent>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Paper variant='outlined' sx={{borderRadius:"12px", boxShadow: "rgba(17, 12, 46, 0.04) 0px 20px 30px 0px",}}>
                        <CardContent>
                            <Typography variant="h6">Cache Efficiency</Typography>
                            <Typography variant="h4">{(cacheEfficiency * 100).toFixed(2)}%</Typography>
                        </CardContent>
                    </Paper>
                </Grid>

           
              

                {/* Charts and Tables */}
                <Grid item xs={12} md={6}>
                    <Paper variant='outlined' sx={{borderRadius:"12px",boxShadow: "rgba(17, 12, 46, 0.04) 0px 20px 30px 0px", padding: 2 }}>
                        <Typography variant="h6" gutterBottom>
                            Total Requests (Total, Cached, Uncached)
                        </Typography>
                        <AreaChart
                            width={500}
                            height={300}
                            data={trafficByDay}
                            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="time" />
                            <YAxis />
                            <Tooltip />
                            <Area type="monotone" dataKey="requests" stackId="1" stroke={areaColors[0]} fill={areaColors[0]} name="Total Requests" />
                            <Area type="monotone" dataKey="cachedRequests" stackId="1" stroke={areaColors[1]} fill={areaColors[1]} name="Cached Requests" />
                            <Area type="monotone" dataKey="unCachedRequests" stackId="1" stroke={areaColors[2]} fill={areaColors[2]} name="Uncached Requests" />
                            <Legend />
                        </AreaChart>
                    </Paper>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Paper variant='outlined' sx={{borderRadius:"12px",boxShadow: "rgba(17, 12, 46, 0.04) 0px 20px 30px 0px", padding: 2 }}>
                        <Typography variant="h6" gutterBottom>
                            Total Bandwidth (Total, Cached, Uncached)
                        </Typography>
                        <AreaChart
                            width={500}
                            height={300}
                            data={trafficByDay}
                            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="time" />
                            <YAxis />
                            <Tooltip />
                            <Area type="monotone" dataKey="totalBytes" stackId="1" stroke={areaColors[0]} fill={areaColors[0]} name="Total Bandwidth" />
                            <Area type="monotone" dataKey="cachedBytes" stackId="1" stroke={areaColors[1]} fill={areaColors[1]} name="Cached Bandwidth" />
                            <Area type="monotone" dataKey="unCachedBytes" stackId="1" stroke={areaColors[2]} fill={areaColors[2]} name="Uncached Bandwidth" />
                            <Legend />
                        </AreaChart>
                    </Paper>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Paper variant='outlined' sx={{borderRadius:"12px",boxShadow: "rgba(17, 12, 46, 0.04) 0px 20px 30px 0px", padding: 2 }}>
                        <Typography variant="h6" gutterBottom>
                            Unique Visitors (Total)
                        </Typography>
                        <AreaChart
                            width={500}
                            height={300}
                            data={trafficByDay}
                            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="time" />
                            <YAxis />
                            <Tooltip />
                            <Area type="monotone" dataKey="uniques" stackId="1" stroke={areaColors[3]} fill={areaColors[3]} name="Unique Visitors" />
                            <Legend />
                        </AreaChart>
                    </Paper>
                </Grid>

                {/* Pie Chart: SSL Distribution */}
                <Grid item xs={12} md={6}>
                    <Paper variant='outlined' sx={{borderRadius:"12px",boxShadow: "rgba(17, 12, 46, 0.04) 0px 20px 30px 0px", padding: 2 }}>
                        <Typography variant="h6" gutterBottom>
                            SSL/TLS Usage
                        </Typography>
                        <PieChart width={400} height={300}>
                            <Pie
                                data={sslDistribution}
                                dataKey="requests"
                                nameKey="ssl"
                                cx="50%"
                                cy="50%"
                                outerRadius={100}
                                fill={pieColors[0]}
                                label
                            >
                                {sslDistribution.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={pieColors[index % pieColors.length]} />
                                ))}

                                <Legend
                                    payload={sslDistribution.map((entry, index) => ({
                                        value: entry.ssl,
                                        type: 'square',
                                        color: pieColors[index % pieColors.length],
                                    }))}
                                />
                            </Pie>
                            <Tooltip />
                            <Legend />
                        </PieChart>
                    </Paper>
                </Grid>

                {/* Bar Chart: Traffic by Country */}
                <Grid item xs={12} md={6}>
                    <Paper variant='outlined' sx={{borderRadius:"12px",boxShadow: "rgba(17, 12, 46, 0.04) 0px 20px 30px 0px", padding: 2 }}>
                        <Typography variant="h6" gutterBottom>
                            Web Traffic Requests by Country
                        </Typography>
                        <BarChart
                            width={400}
                            height={300}
                            data={trafficByCountry}
                            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="country" />
                            <YAxis />
                            <Tooltip />
                            <Bar dataKey="requests" fill={barColors[0]} />
                            <Legend
                                payload={[{ value: 'Requests', type: 'square', color: barColors[0] }]}
                            />
                        </BarChart>
                    </Paper>
                </Grid>

                {/* Table: Traffic by Country */}
                <Grid item xs={6}>
                    <Paper variant='outlined' sx={{borderRadius:"12px",boxShadow: "rgba(17, 12, 46, 0.04) 0px 20px 30px 0px", padding: 2 }}>
                        <Typography variant="h6" gutterBottom>
                            Traffic by Country (Table View)
                        </Typography>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Country</TableCell>
                                        <TableCell align="right">Requests</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {trafficByCountry.map((row) => (
                                        <TableRow key={row.country}>
                                            <TableCell>{row.country}</TableCell>
                                            <TableCell align="right">{row.requests}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
};

export default TrafficDashboard;
