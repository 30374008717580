import { Fade, Grid, IconButton, ListItemAvatar, ListItemButton, ListItemText, Paper, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { AiFillThunderbolt } from 'react-icons/ai'
import { FiBox } from 'react-icons/fi'
import { MdOutlineLightbulb } from 'react-icons/md'
import { RiDatabase2Fill, RiGitBranchFill } from 'react-icons/ri'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import codtMonitorImg from '../../../Assets/images/costmonitor.png'
import CreateNewApp from './Main'
import { CONFIG_APP_BASE_COMPANY } from '../../../configApp'

export default function New() {
    let history = useHistory();
    const role = localStorage.getItem("role")
    const [openCreateNewApp, setopenCreateNewApp] = React.useState(false);
    const [showTrending, setshowTrending] = React.useState(false);
    useEffect(() => {
    }, [openCreateNewApp]);
    return (
        <div>
            <CreateNewApp hideCreateAppButton={true} openCreateNewApp={openCreateNewApp} handleDisableopenCreateNewApp={() => setopenCreateNewApp(false)} />
            <Grid container spacing={1}>
                <Grid item xs={12} sm={7}>
                    <div className='demo_dashboard'>
                        <Grid container >

                            {!showTrending && <Grid item xs={12} sm={12} sx={{ mt: 5, ml: 1 }}>
                                <Fade in={true} mountOnEnter unmountOnExit style={{ transformOrigin: '0 0 0' }} {...(true ? { timeout: 500 } : {})}>
                                    <Typography variant='h3' sx={{ mt: 2, mb: 2, fontWeight: "lighter", fontFamily: "Rubik" }} color={"primary"}>Welcome to {CONFIG_APP_BASE_COMPANY === "nife" ? "Oikos" : "Zeke"}</Typography>
                                </Fade>
                                <Typography variant='p' sx={{ my: 2 }}>The fastest way to Build, Manage, Deploy, and Scale!</Typography>


                            </Grid>}

                            {CONFIG_APP_BASE_COMPANY === "nife" && <Grid item xs={12}>

                                <ListItemButton sx={{ mx: 1, mt: 2, p: 0 }} onMouseLeave={() => { setshowTrending(false) }} onMouseEnter={() => { setshowTrending(true) }} onClick={() => history.push("/Cost-Monitoring")}>
                                    <ListItemAvatar>
                                        <IconButton><AiFillThunderbolt style={{ color: "rgb(93, 134, 249)" }} /></IconButton>
                                    </ListItemAvatar>
                                    <ListItemText primary={<><Typography sx={{ fontWeight: "bold" }}> <span className='trendingnowtext'>
                                        TRENDING NOW
                                    </span></Typography></>} secondary="Cost Monitoring with Platus." />
                                </ListItemButton>
                                {showTrending && <div>
                                    <Fade in={true}>
                                        <div>
                                            <Paper onClick={() => history.push("/Cost-Monitoring")} onMouseLeave={() => { setshowTrending(false) }} onMouseEnter={() => { setshowTrending(true) }} variant="outlined" sx={{cursor:"pointer", m: 1, p: 2, borderRadius: "12px", boxShadow: "rgba(17, 12, 46, 0.04) 0px 20px 30px 0px" }}>
                                                <Typography sx={{ pb:2 }}>Connect your   <img src="https://upload.wikimedia.org/wikipedia/commons/9/93/Amazon_Web_Services_Logo.svg" style={{width:"20px",height:"auto",margin:"0px 5px"}}/> AWS,   <img src="https://brandeps.com/logo-download/G/Google-Cloud-logo-vector-01.svg" style={{width:"20px",height:"auto",margin:"0px 5px"}}/>GCP and   <img src="https://upload.wikimedia.org/wikipedia/commons/f/fa/Microsoft_Azure.svg" style={{width:"20px",height:"auto",margin:"0px 5px"}}/> Azure account for detailed analysis and In-depth reports on your cloud expenses with alerts, Try Cost Monitoring with Platus.</Typography>
                                              
                                              
                                              
                                                <img src={codtMonitorImg} alt="Platus Cost monitoring" style={{ width: '100%', height: 'auto' }} />
                                            </Paper>
                                        </div>
                                    </Fade>
                                </div>}

                            </Grid>}


                            <Grid item xs={12} sm={12} md={6} sx={{ mt: 1 }}>
                                <Paper variant="outlined" sx={{ m: 1, p: 1, borderRadius: "12px", boxShadow: "rgba(17, 12, 46, 0.04) 0px 20px 30px 0px" }}>
                                    <div className='demo__create_app'>
                                        <ListItemButton disabled={role === "Viewer"} onClick={() => setopenCreateNewApp(true)}>
                                            <ListItemAvatar>
                                                <IconButton><FiBox /></IconButton>
                                            </ListItemAvatar>
                                            <ListItemText primary={<><Typography sx={{ fontWeight: "bold" }}>Create an app</Typography></>} secondary="Create, configure, deploy, and customize web apps effortlessly." />
                                        </ListItemButton>
                                    </div>
                                </Paper>
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} sx={{ mt: 1 }}>
                                <Paper variant="outlined" sx={{ m: 1, p: 1, borderRadius: "12px", boxShadow: "rgba(17, 12, 46, 0.04) 0px 20px 30px 0px" }}>
                                    <div className="demo_workload">
                                        <ListItemButton disabled={role === "Viewer"} onClick={() => history.push("/workloads?create=new")}>
                                            <ListItemAvatar>
                                                <IconButton><RiGitBranchFill /></IconButton>
                                            </ListItemAvatar>
                                            <ListItemText primary={<><Typography sx={{ fontWeight: "bold" }}>Create a workload</Typography></>} secondary="Workloads are the best way to manage multiple app environments." />
                                        </ListItemButton>
                                    </div>
                                </Paper>
                            </Grid>



                            <Grid item xs={12} sm={12} md={6}>
                                <Paper variant="outlined" sx={{ m: 1, p: 1, borderRadius: "12px", boxShadow: "rgba(17, 12, 46, 0.04) 0px 20px 30px 0px" }}>
                                    <div className="demo_database">
                                        <ListItemButton disabled={role === "Viewer"} onClick={() => history.push("/databases?create=new")}>
                                            <ListItemAvatar>
                                                <IconButton><RiDatabase2Fill /></IconButton>
                                            </ListItemAvatar>
                                            <ListItemText primary={<><Typography sx={{ fontWeight: "bold" }}>Create a database</Typography></>} secondary="Establish a structured and organized database and deploy efficiently." />
                                        </ListItemButton>
                                    </div>
                                </Paper>
                            </Grid>



                            <Grid item xs={12} sm={12} md={6}>
                                <Paper variant="outlined" sx={{ m: 1, p: 1, borderRadius: "12px", boxShadow: "rgba(17, 12, 46, 0.04) 0px 20px 30px 0px" }}>
                                    <div className="demo_learn_more">
                                        <ListItemButton disabled={role === "Viewer"} onClick={() => window.open('https://docs.nife.io/docs/Introduction/Nife')}>
                                            <ListItemAvatar>
                                                <IconButton><MdOutlineLightbulb /></IconButton>
                                            </ListItemAvatar>
                                            <ListItemText primary={<><Typography sx={{ fontWeight: "bold" }}>Learn more</Typography></>} secondary="Take a look at the documentation to discover all of Nife's capabilities. " />
                                        </ListItemButton>
                                    </div>
                                </Paper>
                            </Grid>



                        </Grid>
                    </div>
                </Grid>





                <Grid item xs={12} sm={12} md={5}>
                    <img src="https://react-nife-2024.s3.ap-south-1.amazonaws.com/welcome.gif" width={"100%"} style={{ padding: "20px" }} />
                </Grid>
            </Grid>
        </div>
    )
}
