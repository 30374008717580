import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Link } from "react-router-dom";

import GetAppIcon from '@mui/icons-material/GetApp';
import SearchIcon from '@mui/icons-material/Search';
import { Fade, TextField } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Skeleton from '@mui/material/Skeleton';
import axios from 'axios';
import { useRouteMatch } from "react-router-dom";
import { useSnackbar } from 'notistack';
import { Octokit } from '@octokit/rest';
import { whichEnv } from '../PlanAndPricing';
import { CONFIG_APP_BASE_COMPANY } from '../../configApp';
var fileDownload = require('js-file-download')

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 3,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
    paddingLeft: "10%",
    padding: 5,
    paddingTop: "3%",
  },
  drawerPaper: {
    background: "linear-gradient(0deg,#4e73df 10%,#618aff 100%)",
    color: "#fff",
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '99.3vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: "0px",
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: "auto",
  },
  header: {
    borderRadius: "12px",
    margin: "10px 24px 24px 24px",
    boxShadow: "rgba(17, 12, 46, 0.05) 0px 30px 50px 05px",
    paddingRight: "10px",
    paddingLeft: "10px",
  },
  card: {
    borderRadius: "12px",
    height: "100%",
    '&:hover': {
      background: theme.palette.background.paper,
      boxShadow: "rgba(58, 59, 69, 0.15) 0px 0.15rem 1.75rem 0px",
      borderRadius: "12px",

    }
  },
  headline: {
    fontSize: "18px",
    margin: "10px"
  },
  link: {
    display: 'flex',
    textDecoration: "none",
    color: '#5d86f9'
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },


}));



export default function Libraries() {
  const [data, setData] = React.useState(null)
  const [searched, setSearched] = React.useState("")
  const match = useRouteMatch();
  const { enqueueSnackbar } = useSnackbar();
  const requestSearch = (searchedVal) => {
    setSearched(searchedVal)
    const filteredRows = data.filter((row) => {
      return row.name.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setRows(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch("");
  };

  const [type, settype] = React.useState('');

  const handleChange = (event) => {
    requestSearch(event.target.value)
    settype(event.target.value);
  };
  const [release, setRelease] = React.useState(null);
  const [rows, setRows] = React.useState([])
  const [loading, setLoading] = React.useState(true)
  const octokit = new Octokit({
    auth: process.env.REACT_APP_SECRET_GIT,
  });

  useEffect(() => {
    document.title = "Libraries"
    axios.get("https://api.github.com/repos/nifetency/nife-release/releases/154946582/assets", { headers: { 'Authorization': `token ${process.env.REACT_APP_SECRET_GIT}`, 'accept': ' application/vnd.github.v3+json ' } }).then((res) => {
      // setData(res.data)
      // setRows(res.data)
      setLoading(false)

    }, (err) => {
      setLoading(false)
    })


    const fetchReleases = async () => {
      try {
        const { data } = await octokit.repos.listReleases({
          owner: 'nifetency',
          repo: 'nife-release',
          per_page: 100,
        });
console.log("lkib",data)
        let matchedRelease = null;

        if (whichEnv === 'prod') {
          matchedRelease = data.find(release => release.tag_name.startsWith('prod'));
        } else if (whichEnv === 'dev') {
          matchedRelease = data.find(release => { return release.tag_name.startsWith('dev') && release.assets.length > 9 });
        }

        setRelease(matchedRelease);
        if (matchedRelease.assets) {
          setData(matchedRelease.assets)
          setRows(matchedRelease.assets)
        }

        console.log(data, "data", matchedRelease)
      } catch (error) {
        console.error('Error fetching releases:', error);
      }
    };

    fetchReleases();

  }, []);


  const classes = useStyles();

  const handelURL = (e) => {
    // fileDownload(e.browser_download_url, e.name)
    enqueueSnackbar("Download started..", {
      variant: 'success',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
    })
  }
  return (
    <div style={{ marginTop: "20px" }}>

      {/* <Typography variant='h6' style={{ fontWeight: "bold", margin: "15px 0px" }}> Libraries </Typography> */}
      <Grid container spacing={2}>
        <Grid item>
          <TextField
            value={searched}
            size={"small"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {searched !== "" &&
                    <IconButton onClick={cancelSearch}>
                      <CloseIcon />
                    </IconButton>}
                </InputAdornment>
              ),
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
            variant="outlined"
            style={{ marginBottom: "10px" }}
            placeholder='Search for Libraries'
            onChange={(e) => requestSearch(e.target.value)}
          />
        </Grid>
        <Grid item>
          <FormControl sx={{ mb: 1, minWidth: 120 }}>
            <InputLabel id="demo-simple-select-label">Show All</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select-outlined"
              value={type}
              size={"small"}
              onChange={handleChange}
              label="Show All"

            >
              <MenuItem value={""}>
                Show All
              </MenuItem>
              <MenuItem value={"windows"}>Windows</MenuItem>
              <MenuItem value={"linux"}>Linux</MenuItem>
              <MenuItem value={"darwin"}>Darwin</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Typography sx={{ mb: 2 }} variant='body1'>Download the command line interface to your local computer to perform administrative tasks on nifectl, How to install? <a target='_blank' href={"https://docs.nife.io/docs/Quick%20Start/Nife%20CLI%20Installation"}>Click here</a></Typography>



      <Grid container spacing={2}>

        {!loading ? rows.map((item, index) => (
          <Fade in={true} key={index}
            style={{ transformOrigin: '0 0 0' }}
            {...(true ? { timeout: 200 * index } : {})}>
            <Grid item xs={4}>
              <Card variant="outlined" className={classes.card}>
                <CardContent>
                  <Typography className={classes.title} color="textSecondary" gutterBottom>
                    {CONFIG_APP_BASE_COMPANY === "nife" ? "nifectl" : "ztl"}
                  </Typography>
                  <Typography variant="h5" component="h2" style={{ fontSize: "15px", fontWeight: "bold" }} noWrap={true}>
                    {item.name.split(/\s*\-\s*/g)[3].charAt(0).toUpperCase() + item.name.split(/\s*\-\s*/g)[3].slice(1) + " " + item.name.split(/\s*\-\s*/g)[4]}
                  </Typography>
                  <Typography variant="body2" component="p">
                    {item.name.split(/\s*\-\s*/g)[2]}
                  </Typography>
                </CardContent>
                <CardActions>
                  <a target='_blank' href={item.browser_download_url}>
                    <Button size="small" onClick={e => { handelURL(item) }}> <GetAppIcon /></Button>
                  </a>
                </CardActions>
              </Card>
            </Grid>
          </Fade>

        )) : <Grid container spacing={3}>

          <Grid item xs>
            <Skeleton variant="rounded" width={"100%"} style={{ borderRadius: "5px" }} height={200} />
          </Grid>



        </Grid>}

        {(!loading && rows.length === 0) && <div style={{ margin: "10px" }}><b>No data </b> <br /> No Results for your query </div>}
      </Grid>


    </div>
  );
}